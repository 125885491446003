import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagenotfoundComponent } from './components/pages/pagenotfound/pagenotfound.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HomeComponent } from './components/pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChannelComponent } from './components/pages/channel/channel.component';
import { HeaderComponent } from './components/common/header/header.component';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { GuardService } from './utilitis/services/guard.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageParamInterceptor } from './shared/language-param.interceptor';
import { SearchresultComponent } from './components/pages/searchresult/searchresult.component';
import { PlayerComponent } from './components/pages/player/player.component';
import { LiveComponent } from './components/pages/live/live.component';
import { ArchivesComponent } from './components/pages/archives/archives.component';
import { AuthInterceptor } from './utilitis/interceptor/authInterceptor';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { ClickoutsideDirective } from './utilitis/directives/clickoutside.directive';
import { YoutubeComponent } from './components/pages/youtube/youtube.component';
import { LivePlayerComponent } from './components/pages/live-player/live-player.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { ItemComponent } from './components/common/item/item.component';
import { TokenInterceptor } from './utilitis/interceptor/token.interceptor';
import { ParentChannelComponent } from './components/pages/parent-channel/parent-channel.component';
import { ParentItemComponent } from './components/common/parent-item/parent-item.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import { LawsComponent } from './components/pages/laws/laws.component';
import { EventsComponent } from './components/common/events/events.component';
import { MultiLiveEventsComponent } from './components/pages/multi-live-events/multi-live-events.component';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import {MessagingModule, Messaging, provideMessaging } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from 'src/environments/environment';
import { LoginModalComponent } from './components/common/login-modal/login-modal.component';
import { CategoryComponent } from './components/common/category/category.component';
import { PlayerSectionComponent } from './components/common/player-section/player-section.component';
import { OverviewComponent } from './components/common/overview/overview.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SidebarComponent,
    ChannelComponent,
    SearchresultComponent,
    PlayerComponent,
    LiveComponent,
    ArchivesComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    ClickoutsideDirective,
    YoutubeComponent,
    LivePlayerComponent,
    YoutubePlayerComponent,
    ItemComponent,
    ParentChannelComponent,
    ParentItemComponent,
    LoaderComponent,
    LawsComponent,
    EventsComponent,
    MultiLiveEventsComponent,
    LoginModalComponent,
    CategoryComponent,
    PlayerSectionComponent,
    OverviewComponent,
  ],
  exports: [
    ItemComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageParamInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // { 
    //   provide: HTTP_INTERCEPTORS, 
    //   useClass: GlobalHttpErrorInterceptor, 
    //   multi: true  
    // },
    GuardService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
