import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from "firebase/messaging";
import { Subject, takeUntil } from 'rxjs';
import { AuthServiceService } from 'src/app/auth/service/auth-service.service';
import { LanguageService } from 'src/app/services/language.service';
import { PlayerService } from 'src/app/services/playerservice/player.service';
import { NavigationService } from 'src/app/utilitis/services/navigation.service';
import { environment } from 'src/environments/environment';
declare var jwplayer: any;
declare var $: any;
declare var td: any;

@Component({
  selector: 'app-live-player',
  templateUrl: './live-player.component.html',
  styleUrls: ['./live-player.component.css'],
})
export class LivePlayerComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  public watch_id: any;
  public current_machine_id: any = 0;
  public multiLiveEvents: any = [];
  public video: any;
  public loading = false;
  diff: any = 0;
  siteLink = ''
  public jwplayer: any = '';
  public videoData: any = { category: {}, is_offline: 0 };
  public lang: any = '';
  public error: any = false;
  public youtubestream_url: any = '';
  public liveDate: any;
  isMobile = (window as any).isMobileLayout();
  private reloadInterval: any;
  private playerInterval: any;


  constructor(
    private playerservice: PlayerService,
    public user: AuthServiceService,
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    public langSerivce: LanguageService,
    private titleService: Title,
    private router: Router,
    private navigation: NavigationService,
    private sanitizer: DomSanitizer
  ) {
    this.lang = this.langSerivce.getLang();
  }

  ngOnInit(): void {
    this.listenForMessages();
    this.activeRoute.queryParams.subscribe((params) => {
      this.watch_id = params['watch_id'];
      if (params['machine_id']) {
        this.current_machine_id = params['machine_id']
        if (this.current_machine_id > 0) {
          this.isopenLink = 'pills-youth-tab'
        }
      }
      this.fetchMultiLiveEvents()
      this.openPlayUrl();
    });
  }

  openPlayUrl() {
    if (!this.user.isLoggedIn()) {
      this.fetchDataOnly();
    } else {
      this.fetchDataWithStream();
    }

    this.reloadInterval = setInterval(() => {
      if (this.videoData.isOffline) this.fetchDataWithStream();
    }, 60000);
  }

  isOpenTab = 'Overview'

  openTab(tab: string) {
    this.isOpenTab = tab;
  }

  fetchDataOnly() {
    this.playerservice.getLiveEventUrl(this.watch_id).subscribe({
      next: (data) => {
        this.error = false;
        this.videoData = data;
        this.titleService.setTitle(this.videoData.title);

      },
      error: (err) => this.handleError(err)
    });
  }

  fetchDataWithStream() {
    this.playerservice.getLiveEventStreamUrl(this.watch_id).subscribe({
      next: (data) => {
        this.error = false;
        this.videoData = data;
        this.youtubestream_url = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/' +
          this.videoData.stream_url +
          '?autoplay=1&fs=1&modestbranding=1&iv_load_policy=3'
        );
        this.titleService.setTitle(this.videoData.title);
        this.setupPlayer(data);
      },
      error: (error) => this.handleError(error)
    });
  }

  setupPlayer(data: any): void {
    let date2 = new Date(data['start_time_timestamp'] * 1000);
    this.videoData['date2'] = date2;
    this.diff = data['start_time_timestamp'] - data['current_time'];

    this.playerInterval = setInterval(() => {
      if (this.diff <= -1) {
        this.loading = false;
        if (
          !this.jwplayer &&
          data.stream_url &&
          !this.loading &&
          !data.restrict &&
          !this.videoData.is_offline
        )
          if (!this.jwplayer)
            if (this.current_machine_id > 0) {
              let fdata = this.multiLiveEvents.find((elemnt: any) => {
                return elemnt.machine_id == this.current_machine_id
              })
              this.isOpenTab = 'multilive'
              this.playVideo(fdata);
            } else {
              this.playVideo(data);
            }
      }
    }, 1000);
  }

  handleError(error: any): void {
    this.error = true;
    if (error.status === 404) {
      this.router.navigate(['/watch'], { queryParams: { watch_id: this.watch_id } });
    }
  }

  playVideo(data: any) {
    if (this.jwplayer) {
      this.jwplayer.remove();
    }

    this.jwplayer = jwplayer('player').setup({
      file: data['stream_url'],
      autostart: true,
      skin: {
        url: '../../../../assets/skin.css',
        name: 'myskin',
      },
      image: data['cover_image'],
      aspectratio: "16:9",
      liveTimeout: 0
    });

    this.jwplayer.on('setupError', (event: any) => this.handleSetupError(data, event));

    this.jwplayer.on('error', (event: any) => this.handlePlayerError(data, event));

    this.jwplayer.on('complete', (evemt: any) => this.handlePlayerComplete(data));

    this.jwplayer.on('ready', () => this.handlePlayerReady(data));


    let isSaved = true
    setInterval(() => {
      this.jwplayer.on('time', (e: any) => {
        if (isSaved) {
          isSaved = false;
        }
      })
      isSaved = true;
    }, 60000)

    this.addWatchHistry({});
  }

  handlePlayerReady(data: any): void {
    const playerContainer = this.jwplayer.getContainer();
    const buttonContainer = playerContainer.querySelector('.jw-button-container');
    const spacer = buttonContainer.querySelector('.jw-text-live');
    $(playerContainer).append(spacer);
    this.saveWatchVideo(1);
  }

  handlePlayerComplete(data: any): void {
    const playerContainer = this.jwplayer.getContainer();
    const previewContaianer = playerContainer.querySelector('.jw-preview');
    $(previewContaianer).css({
      'background-image': 'url(' + data['cover_image'] + ')',
    });
    const iconDisplay = playerContainer.querySelector('.jw-display-icon-display');
    const replayicon = iconDisplay.querySelector('.jw-icon-display');
    const replay = $(replayicon).attr('aria-label');
    if (replay === 'Replay') {
      $(replayicon).on('click', () => this.fetchDataWithStream());
    }
  }

  handlePlayerError(data: any, event: any): void {
    const playerContainer = this.jwplayer.getContainer();
    const errorConntaianer = playerContainer.querySelector('.jw-error-msg');
    if (this.current_machine_id > 0) {
      const text = `<div style="display: flex; flex-direction: column" class="jw-error-text jw-reset-text" style="text-align: center" dir="auto" data-nosnippet="">
          <h3 style="margin:0; text-align: center;">An Error occurred during delivery</h3>
          <p style="margin:0; text-align: center;">Return to main channel</p>
          <button class="replay-button-error1" style="margin: 0;">OK</button>
        </div>`;
      const icon = errorConntaianer.querySelector('.jw-icon');
      $(icon).css('display', 'none');
      const d = errorConntaianer.querySelector('.jw-info-container');
      $(d).html(text);
      $('body').find('.replay-button-error1').on('click', () => {
        this.current_machine_id = 0;
        this.playVideo(this.videoData);
      });
    } else {
      $(errorConntaianer).append(
        $('<div class="jw-icon jw-reset replay-button-error1"><svg class="jw-svg-icon jw-svg-icon-error" xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-replay1" viewBox="0 0 240 240" focusable="false"><path d="M120,41.9v-20c0-5-4-8-8-4l-44,28a5.865,5.865,0,0,0-3.3,7.6A5.943,5.943,0,0,0,68,56.8l43,29c5,4,9,1,9-4v-20a60,60,0,1,1-60,60H40a80,80,0,1,0,80-79.9Z"></path></svg></div>')
      );
      $('body').find('.replay-button-error1').on('click', () => this.openPlayUrl());
    }
  }

  handleSetupError(data: any, event: any): void {
    $('#player').css('height', ($(window).innerHeight() * 83) / 100);
    const playerContainer = this.jwplayer.getContainer();
    const errorConntaianer = playerContainer.querySelector('.jw-error-msg');
    if (this.current_machine_id > 0) {
      var text = `<div class="jw-error-text jw-reset-text" style="text-align: center" dir="auto" data-nosnippet="">\
            <h3 style="margin:0;text-align: center;">An Error occured during delivery</h3>\
            <p style="margin:0; text-align: center;">Return to main channel</p>\
            <button class="replay-button-error1" style="padding: 5px;">OK</button>
          </div>`
      var icon = errorConntaianer.querySelector('.jw-icon');
      $(icon).css('display', 'none');
      var d = errorConntaianer.querySelector('.jw-info-container');
      const play = $(d).html(
        $(
          text
        )
      );
      $('body')
        .find('.replay-button-error1')
        .on('click', () => {
          this.playVideo(data);
          this.current_machine_id = 0;
        });
    } else {
      const play = $(errorConntaianer).append(
        $(
          ' <div class="jw-icon jw-reset replay-button-error1"><svg class="jw-svg-icon jw-svg-icon-error" xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-replay1" viewBox="0 0 240 240" focusable="false"><path d="M120,41.9v-20c0-5-4-8-8-4l-44,28a5.865,5.865,0,0,0-3.3,7.6A5.943,5.943,0,0,0,68,56.8l43,29c5,4,9,1,9-4v-20a60,60,0,1,1-60,60H40a80,80,0,1,0,80-79.9Z"></path></svg></div> '
        )
      );

      $('body')
        .find('.replay-button-error1')
        .on('click', () => {
          this.openPlayUrl();
        });
    }

    $('#player').css({
      'background-image': 'url(' + data['cover_image'] + ')',
      'background-size': 'cover',
    });
  }

  saveWatchVideo(time: any) {
    let data = {
      item_id: this.watch_id,
      item: 'live',
      video_type: 'live',
      watch_duration: Math.ceil(time),
      duration: Math.ceil(this.jwplayer.getDuration()),
      platform: 'web',
      is_dvr: 0
    };

    if (this.videoData.stream_url.includes('_dvr')) {
      data.video_type = "dvr";
      data.item = "dvr";
      data.is_dvr = 1;
    }
    this.addWatchHistry(data);
  }

  ngOnDestroy(): void {
    clearInterval(this.reloadInterval);
    clearInterval(this.playerInterval)
    if (this.user.isLoggedIn() && this.jwplayer) {
      let data = {
        item_id: this.watch_id,
        video_id: this.watch_id,
        item: 'live',
        video_type: 'live',
        is_dvr: 0,
        is_live: 1,
        platform: 'web',
        charge_status: this.user.getChargeStatus(),
        watch_duration: Math.ceil(this.jwplayer.getPosition()),
        duration: 0,
        ottId: this.user.getOttId(),
        insert_id: (window as any).e5()
      };

      let webdata = {
        video_id: this.watch_id,
        video_type: 'live',
        is_dvr: 0,
        platform: 'web',
        charge_status: this.user.getChargeStatus(),
        watch_duration: Math.ceil(this.jwplayer.getPosition()),
        duration: 0,
        ottId: this.user.getOttId(),
        insert_id: (window as any).e5()
      };

      if (this.videoData.stream_url.includes('_dvr')) {
        data.video_type = "dvr";
        data.item = "dvr";
        data.is_dvr = 1;
        data.is_live = 0;
        webdata.video_type = "dvr"
        webdata.is_dvr = 1
      }
      this.playerservice
        .recordwatchHistory(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => { });
      td.addRecord(
        'user_logs',
        webdata,
        function () { },
        function () { }
      );
    }

    if (this.jwplayer) {
      this.jwplayer.remove();
    }
  }

  back(): void {
    this.navigation.back();
  }

  display(seconds: any) {
    const format = (val: any) => `0${Math.floor(val)}`.slice(-2);
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;
    return [hours, minutes, seconds % 60].map(format).join(':');
  }

  showReloadBanner(): boolean {
    if (this.diff >= 1 && !this.videoData.restrict) {
      if (this.videoData.is_offline) {
        return true;
      }
      return false;
    }
    return false;
  }

  addWatchHistry(data: any) {
    let watch_dutation = 0;
    if (this.jwplayer) {
      watch_dutation = this.jwplayer.getPosition()
    }
    let duration = 0;
    if (this.jwplayer) {
      duration = this.jwplayer.getDuration()
    }

    data = {
      item: 'live',
      video_id: this.watch_id,
      video_type: 'live',
      watch_duration: 1,
      duration: Math.abs(Math.ceil(this.jwplayer.getDuration())),
      platform: 'web',
      is_dvr: 0,
      is_live: 1,
      charge_status: this.user.getChargeStatus(),
      ottId: this.user.getOttId(),
      insert_id: (window as any).e5()
    };

    let webdata = {
      video_id: this.watch_id,
      video_type: 'live',
      is_dvr: 0,
      platform: 'web',
      charge_status: this.user.getChargeStatus(),
      watch_duration: Math.ceil(this.jwplayer.getPosition()),
      duration: 0,
      ottId: this.user.getOttId(),
      insert_id: (window as any).e5()
    };

    if (this.videoData.stream_url.includes('_dvr')) {
      data.video_type = "dvr";
      data.item = "dvr";
      data.is_dvr = 1;
      data.is_live = 0;
      webdata.is_dvr = 1;
      webdata.video_type = "dvr"
    }

    td.addRecord(
      'user_logs',
      webdata,
      function () { },
      function () { }
    );
    data.item_id = this.watch_id;
    this.playerservice
      .recordwatchHistory(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => { });
  }


  async fetchMultiLiveEvents() {
    try {
      this.multiLiveEvents = await this.playerservice.getMultiLiveLiveEvents(this.watch_id);
    } catch (error) {
      throw error;
    }
  }


  changeStream(event: any) {
    if (event.update) {
      this.fetchMultiLiveEvents();
      return;
    }
    this.current_machine_id = event.machine_id
    if (
      event.stream_url &&
      !this.loading &&
      !this.videoData.restrict &&
      !this.videoData.is_offline
    ) {
      // this.isOpenTab = 'multilive';
      jwplayer("player").load([{ file: event.stream_url }]);
      jwplayer("player").play();
    }
  }

  openLink() {
    this.siteLink = 'https://www.dartslive.com/regist/?hunt=SdMNmCVyL9I57i92DJOu'
    if (this.langSerivce.currentLang == 'jp') {
      this.siteLink = 'https://www.dartslive.com/regist/dartslivetv/ja/?hunt=SdMNmCVyL9I57i92DJOu'
    }
    window.open(this.siteLink)
  }

  listenForMessages() {
    const app = initializeApp(environment.firebase);
    const a = getMessaging();
    onMessage(a, (message: any) => {
      console.log('Message received. ', message, this.current_machine_id);
      if (message.data.content_type == '') {
        this.current_machine_id = 0;
        this.fetchDataWithStream()
      } else {
        if (this.current_machine_id == message.data.machine_id) {
          if (message.data.status == 'end') {
            this.current_machine_id = 0;
          }
          if (message.data.status == 'start') {
          }
          console.log(this.videoData, "this.videoData")
          this.playVideo(this.videoData);
        }
        this.fetchMultiLiveEvents();
      }
    });
  }

  isopenLink = 'pills-chefsprogram-tab';
  public OpenLink(link: any) {
    this.isopenLink = link;
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    console.log(width);
    this.isMobile = (window as any).isMobileLayout();
  }



}
