<div class="player-section py-1">
  <div *ngIf="videoData.url_type === 'youtube'" class="embed-youtube">
    <ng-container *ngIf="videoData; else notLoggedIn">
      <iframe allow="fullscreen" style="width: 100%; aspect-ratio: 16 / 9" allow="autoplay"
        [src]="videoData.youtubeStreamUrl">
      </iframe>
    </ng-container>
  </div>

  <!-- Non-YouTube Content -->
  <div *ngIf="videoData.url_type !== 'youtube'" class="video">
    <ng-container>
      <div *ngIf="videoData.cover_image && !videoData.restrict">
        <img [src]="videoData.cover_image" alt="Cover Image" />
      </div>
    </ng-container>
  </div>
</div>

<ng-template #notLoggedIn>
  <app-login-modal></app-login-modal>
</ng-template>