<div class="show" style="overflow-x: hidden; max-height: 590px;" *ngIf="!loading">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h2>{{category.title_en}}</h2>

  </div>
  <div class="container">
  <div class="row">
    <ng-container>
      <div class="col-12" style="cursor: pointer; text-align: left;padding: 5px;" *ngFor="let data of videos['data']"
        >

        <div class="row">

          <div class="col-md-5">
            <a [routerLink]="data.type === 'live' ? '/live-watch' : ['/watch']"
            [queryParams]="{ watch_id: data.id }">
                <img [src]="data.icon_img" [alt]="data.title" width="100%" />
            </a>

          </div>
          <div class="col-md-7">
            <p style="font-weight: 500; margin:0;" *ngIf="data.title">
              {{ data.title }}
            </p>


            <p> {{ data.date }}</p>
          </div>

        </div>

      </div>
    </ng-container>
  </div>
  </div>
  <div class="show" style="overflow-x: hidden; max-height: 590px;" *ngIf="loading">
    loading...
  </div>
